import * as React from "react"

import Layout from "../components/layout/layout"
import HeroWithImageBackground from "../components/sections/hero-with-image-background"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo />

    <HeroWithImageBackground />
  </Layout>
)

export default IndexPage
