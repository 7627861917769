import React from "react"
// import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
import styled from 'styled-components'

import backgroundImage from './background.jpg'

const StyleDiv = styled.div`
  background: #000000 url(${backgroundImage});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;

  @media (max-width: 767px) {
    background-size: contain;
  }
`

const HeroWithImageBackground = () => (
  <>
    <Helmet>
      <link rel="preload" as="image" href={backgroundImage} />
    </Helmet>

    <StyleDiv></StyleDiv>
  </>
)

export default HeroWithImageBackground